@tailwind base; /* Resets and global styles */
@tailwind components; /* Reusable components */
@tailwind utilities; /* Utility classes */

/* Add any global styles below */
body {
  font-family: 'Inter', sans-serif;
  background-color: #f9fafb; /* Tailwind's light background color */
  margin: 0;
}
